<template>
  <div class="col-8 mx-auto mt-10">
    <div v-if="!running && !result">
      <div class="mb-3 mt-5">
        <label for="exampleFormControlTextarea1" class="form-label fs-1">JSON Input</label>
        <textarea class="form-control" v-model="input" @focus="clear_error" id="exampleFormControlTextarea1"
                  rows="13"></textarea>
      </div>
      <div v-if="error" class="fs-3 text-danger">
        {{ errMsg }}
      </div>

      <button type="button" @click="Run" class="mt-5 btn btn-dark">Start</button>
    </div>
    <div v-if="running  && !result" class="mb-3 mt-5">
      <div class="scaling-squares-spinner m-auto">
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
        <div class="square"></div>
      </div>
    </div>
    <p v-if="running  && !result" class="fs-1 mt-4">{{ msg }}</p>
    <div v-if="!result && running && pr !== -1" class="fs-1 mt-4 col-6 mx-auto">
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-dark" role="progressbar"
             :style="'width:' + pr +'%;'" aria-valuenow="25" aria-valuemin="0"
             aria-valuemax="100">{{ pr }}%
        </div>
      </div>
    </div>
    <div v-if="result">
      <div class="mb-3 mt-5">
        <label for="exampleFormControlTextarea1" class="form-label fs-3">Output</label>
        <textarea class="form-control" v-model="input" @focus="clear_error" id="exampleFormControlTextarea1"
                  rows="20"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Main-Translator",
  props: {
    Change_page: {
      type: Function
    },
    token: {
      type: String
    }
  },
  created() {
    this.beautify = require("json-beautify");
  },
  methods: {
    parseJson(json) {
      try {
        return JSON.parse(json)
      } catch (ignored) {
        console.log(ignored.toString());
        return null;
      }
    },
    clear_error: function () {
      this.error = false;
      this.errMsg = "";
    },
    Run: function () {
      this.running = true;
      this.tr_j_data = [];
      this.msg = "Parsing JSON input";
      this.j_data = this.parseJson(this.input);
      if (this.j_data === null) {
        this.error = true;
        this.errMsg = "Error parsing JSON input!";
        this.running = false;
        return;
      }
      this.msg = "Queueing queries";
      console.log(this.j_data.length);
      this.count = this.j_data.length;
      this.t = Math.ceil(this.count / this.splitCount);

      let Queue = require('queue-fifo');
      this.queue = new Queue();
      this.j_data.forEach((query) =>{
        this.queue.enqueue(query);
      });

      let that = this;
      setTimeout(function () {
        that.query();
      }, 1000);
    },
    query: function () {
      this.msg = "Processing...";
      this.pr = 0;
      for (let i = 0; i < this.t; i++) {
        this.translate();
      }
    },
    translate: async function () {
      let query = this.queue.dequeue();
      this.error = false;
      this.loading = true;
      let formData = new URLSearchParams();
      formData.append('from', "en_GB");
      formData.append('to', "am_ET");
      formData.append('text', query['source']);
      formData.append('platform', "dp");
      formData.append('is_return_text_split_ranges', "false");
      await axios('https://api-b2b.backenster.com/b1/api/v3/translate', {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "authorization": this.$props.token
        },
        data: formData
      }).then((response) => {
        // console.log(response)
        query['target'] = response.data['result'];
        this.tr_j_data[query['num']] = query;
      }).catch((error) => {
        this.error_pit = error;
        query['target'] = "Conversion Error";
        this.tr_j_data[query['num']] = query;
      }).finally(() => {
        this.done++;
        if(!this.queue.isEmpty()){
          this.translate();
        }
        this.pr = Math.floor((100 * (this.done / this.count)));
        if (this.done === this.count) {
          this.running = false;
          this.result = true;
          this.input = this.beautify(this.tr_j_data, null, 2, 100);
        }
      });
    }
  },
  data() {
    return {
      loading: false,
      input: null,
      running: false,
      count: 0,
      splitCount: 500,
      t: 0,
      done: 0,
      pr: -1,
      queue: null,
      beautify: null,
      error_pit: null,
      j_data: [],
      tr_j_data: {},
      msg: "",
      result: false,
      error: false,
      errMsg: "",
      test: false
    }
  }
}
</script>

<style scoped>

.mt-10 {
  margin-top: 100px
}

.scaling-squares-spinner, .scaling-squares-spinner * {
  box-sizing: border-box;
}

.scaling-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: scaling-squares-animation 1250ms;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
}

.scaling-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  margin-right: auto;
  margin-left: auto;
  border: calc(65px * 0.04 / 1.3) solid #ffffff;
  position: absolute;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
}

.scaling-squares-spinner .square:nth-child(1) {
  animation-name: scaling-squares-spinner-animation-child-1;
}

.scaling-squares-spinner .square:nth-child(2) {
  animation-name: scaling-squares-spinner-animation-child-2;
}

.scaling-squares-spinner .square:nth-child(3) {
  animation-name: scaling-squares-spinner-animation-child-3;
}

.scaling-squares-spinner .square:nth-child(4) {
  animation-name: scaling-squares-spinner-animation-child-4;
}


@keyframes scaling-squares-animation {

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes scaling-squares-spinner-animation-child-1 {
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-2 {
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-4 {
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}

</style>