<template>
  <div class="row align-items-center">
    <div class="col mt-5 pt-5">
      <div v-if="loading">
        <div class="scaling-squares-spinner m-auto">
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
          <div class="square"></div>
        </div>
        <p class="fs-3 mt-5">Loading...</p>
      </div>
      <div v-else class="text-white">
        <p class="fs-1 mb-5">{{ text }}</p>
        <p class="fs-1" v-if="!error">{{ tr_text }}</p>
        <div v-if="!error">
          <button type="button" @click="change_page()" class="btn btn-dark">Continue</button>
        </div>
        <p class="mt-5 pt-4 fs-3 text-white" v-if="error">
          There seems to be a problem!
        </p>
        <a class="fs-3 text-decoration-none text-white hover" target="_blank" v-if="error" href="https://t.me/b_bahru">
          Contact Me
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-telegram"
               viewBox="0 0 16 16">
            <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Start-Process",
  props: {
    Change_page: {
      type: Function
    },
    token: {
      type: String
    }
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.load_token();
    }, 500);
  },
  methods: {
    change_page: function(){
      this.$props.Change_page(true);
    },
    load_token: async function(){
      this.error = false;
      this.loading = true;
      let formData = new URLSearchParams();
      formData.append('from', "en_GB");
      formData.append('to', "am_ET");
      formData.append('text', this.text);
      formData.append('platform', "dp");
      formData.append('is_return_text_split_ranges', "false");
      await axios('https://api-b2b.backenster.com/b1/api/v3/translate', {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "authorization": this.$props.token
        },
        data: formData
      }).then((response) => {
        // console.log(response);
        let data = response.data;
        this.tr_text = data['result']
        this.loading = false;
      }).catch((error) => {
        this.error_pit = error;
        this.error = true;
        this.loading = false;
      });
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      error_pit: null,
      text: "Welcome",
      tr_text: "",
    }
  }
}
</script>

<style scoped>

.hover:hover{
  color: #d7d7d7 !important;
}

.scaling-squares-spinner, .scaling-squares-spinner * {
  box-sizing: border-box;
}

.scaling-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: scaling-squares-animation 1250ms;
  animation-iteration-count: infinite;
  transform: rotate(0deg);
}

.scaling-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  margin-right: auto;
  margin-left: auto;
  border: calc(65px * 0.04 / 1.3) solid #ffffff;
  position: absolute;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
}

.scaling-squares-spinner .square:nth-child(1) {
  animation-name: scaling-squares-spinner-animation-child-1;
}

.scaling-squares-spinner .square:nth-child(2) {
  animation-name: scaling-squares-spinner-animation-child-2;
}

.scaling-squares-spinner .square:nth-child(3) {
  animation-name: scaling-squares-spinner-animation-child-3;
}

.scaling-squares-spinner .square:nth-child(4) {
  animation-name: scaling-squares-spinner-animation-child-4;
}


@keyframes scaling-squares-animation {

  50% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes scaling-squares-spinner-animation-child-1 {
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-2 {
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-4 {
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}

</style>